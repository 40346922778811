import { Col, ContactForm, Container, Link, Row, Text, Title } from '@zigurous/react-components'; // prettier-ignore
import emailjs from 'emailjs-com';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Page } from '../components';

const metadata = {
  url: 'https://zigurous.com/contact',
  title: 'Zigurous • Contact',
  description:
    'Contact Zigurous for business inquiries or support on any assets they have developed.',
};

function Contact({ location }) {
  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_gmail',
        'contact_form',
        e.target,
        'user_GRQsOTQH0PlLrHBdGpxHi'
      )
      .then(
        () => {
          alert(
            'Thank you for your message! We will get back to you as soon as possible.'
          );
          if (form.current) {
            form.current.reset();
          }
        },
        () => {
          alert('Message failed to send. Please try again.');
        }
      );
  }

  return (
    <Page className="contact" location={location} metadata={metadata}>
      <Container className="margin-top-xxxl" fluid>
        <Row>
          <Col className="margin-bottom-xxl" lg={6}>
            <Title marginBottom="xl" size={1} tag="h2">
              FAQ
            </Title>
            <Text marginBottom="xxl">
              <span className="display-inline-block margin-bottom-md">
                <b>Can I use your game assets in a commercial project?</b>
              </span>
              <br />
              <span className="color-foreground-muted">
                Yes, all of our assets can be used in both commercial and
                non-commercial projects. See the included license file attached
                to the asset package or visit the asset page for more details.
              </span>
            </Text>
            <Text marginBottom="xxl">
              <span className="display-inline-block margin-bottom-md">
                <b>Do I need to provide attribution to use your game assets?</b>
              </span>
              <br />
              <span className="color-foreground-muted">
                No, none of our assets require attribution to use. If you would
                still like to provide credit then you can refer to our work
                using the name &quot;Zigurous&quot; or &quot;zigurous.com&quot;.
              </span>
            </Text>
            <Text marginBottom="xxl">
              <span className="display-inline-block margin-bottom-md">
                <b>Which games engines can I use your assets in?</b>
              </span>
              <br />
              <span className="color-foreground-muted">
                All of our assets are designed for the Unity game engine;
                however, you are free to use any of our assets in any game
                engine if the engine supports them. We cannot offer any help
                implementing our assets outside of the Unity game engine.
              </span>
            </Text>
            <Text marginBottom="xxl">
              <span className="display-inline-block margin-bottom-md">
                <b>I have an idea for a new asset!</b>
              </span>
              <br />
              <span className="color-foreground-muted">
                We would love to hear what kind of assets you are interested in
                using.{' '}
                <Link external to="https://twitter.com/Zigurous">
                  Tweet us your idea
                </Link>{' '}
                and if there is enough demand we can start working on a new
                asset package.
              </span>
            </Text>
          </Col>
          <Col lg={1} />
          <Col lg={5}>
            <Title tag="h1">Contact</Title>
            <Text>
              Join us on{' '}
              <Link external to="https://discord.gg/DdYyWVb">
                Discord
              </Link>{' '}
              for support or to offer feedback. Let us know how we can help you.
              Fill out the form below or send an email to{' '}
              <span className="font-weight-500">adam@zigurous.com</span> for
              business inquiries.
            </Text>
            <ContactForm onSubmit={sendEmail} ref={form} />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

Contact.propTypes = {
  location: PropTypes.object,
};

export default Contact;
